@import 'assets/styles/variables';

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: clamp(1.5rem, 1vw, 2.5rem) clamp(1rem, 2vw, 2rem);
  background-color: $background-color;
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: clamp(0.5rem, 1vw, 1rem);
}

@media (max-width: 768px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .iconContainer {
    justify-content: space-between;
    margin-top: 1rem;
    gap: 0;
  }

  .greetingContainer {
    margin-bottom: 0.5rem;
  }
}
