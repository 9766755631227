@import 'assets/styles/_variables.scss';

.dashboardContainer {
  padding: clamp(1rem, 2.5vw, 1.5rem);
  overflow-y: auto;
  box-sizing: border-box;

  .MuiGrid-item {
    display: flex;
    flex-direction: column;
  }

  .MuiPaper-root {
    flex: 1;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $stats-count-color;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: $background-color;
  }
}
