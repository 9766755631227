.faqContainer {
}

.accordion {
  margin-bottom: 1rem !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  background-color: #f7f9fc !important;
}

.accordionTitle {
  font-weight: bold !important;
  color: #003366;
  font-size: clamp(0.75rem, 1.5vw, 1.1rem) !important;
}

.accordionContent {
  color: #666666;
}

.expandIcon {
  color: #003366;
}
