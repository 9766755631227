@import 'assets/styles/variables';

.greeting {
  font-size: clamp(1rem, 1.5vw, 1.25rem);
  font-weight: bold;
  color: $text-color;
  display: flex;
  align-items: center;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: clamp(0.5rem, 1vw, 1rem);
}
