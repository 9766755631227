@import 'assets/styles/_variables.scss';

.customTabs {
  display: flex;
  margin-bottom: 1rem;
  border-bottom: solid 2px #ecedef;

  .MuiTab-root {
    text-transform: none;
    font-weight: bold;
    color: #666666;
    border-radius: 8px 8px 0 0;
    padding: 12px 24px;
    min-width: 0;
    min-height: 0;
    margin-right: 16px;

    &.Mui-selected {
      color: #003466;
      background-color: #f5f7fc;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
    }
  }

  .tab {
    background-color: transparent;
    text-transform: none;

    &:hover {
      background-color: #f5f7fc;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }

  .activeTab {
    background-color: #f5f7fc;
    text-transform: none;
    color: #003466 !important;
    font-weight: bold;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}
