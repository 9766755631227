@import 'assets/styles/variables';

.loginContainer {
  height: 100vh;
}

.leftSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: clamp(1rem, 4vw, 3rem);
}

.rightSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: clamp(1rem, 4vw, 3rem);
}
