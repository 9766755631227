@import 'assets/styles/variables';

.settingsContainer {
  .pageTitle {
    font-weight: bold;
    color: #003466;
    margin-bottom: 1rem;
  }

  .formContainer {
    border-radius: 8px;
  }

  .profileHeader {
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 1rem;
    background: #F4F7FCBF;
    padding: 1rem;

    .avatarContainer {
      position: relative;
      display: inline-block;
      
      .avatar {
        width: 100px;
        height: 100px;
        margin-bottom: 1rem;
        border: 6px solid #DFCBC5;
      }

      .cameraIcon {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        margin-bottom: 0.5rem;
      }
    }

    .userName {
      font-weight: bold;
      color: #003466;
    }
  }

  .formFields {
    margin-bottom: 2rem;

    .MuiTextField-root {
      .MuiOutlinedInput-root {
        border-radius: 8px;
        background-color: #f4f7fc;
      }

      .MuiInputLabel-outlined {
        color: #666666;
      }
    }
  }

  .actionButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .cancelButton {
      border-radius: 8px;
      color: #003466;
      border-color: #003466;
      text-transform: none;

      &:hover {
        background-color: #f4f7fc;
        border-color: #003466;
      }
    }

    .saveButton {
      border-radius: 8px;
      background-color: #003466;
      text-transform: none;

      &:hover {
        background-color: #002244;
      }
    }
  }
}
