@import 'assets/styles/variables';

$scrollbar-thumb-color: #b0bec5;
$scrollbar-thumb-hover-color: #90a4ae;
$scrollbar-track-color: #eceff1;
$border-color: #e0e0e0;
$text-color: #333;
$text-muted-color: #9e9e9e;

$border-radius: clamp(4px, 0.5vw, 8px);
$max-width: clamp(200px, 30vw, 300px);
$margin-bottom: clamp(5px, 1vw, 10px);
$scrollbar-width: clamp(2px, 0.5vw, 4px);
$padding: clamp(5px, 1vw, 10px);
$font-size: clamp(0.7rem, 2vw, 0.9rem);
$font-size-small: clamp(0.6rem, 1.5vw, 0.8rem);
$avatar-size: clamp(40px, 10vw, 50px);

.popover {
  .MuiPaper-root {
    border-radius: $border-radius;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    max-width: $max-width;
    width: 100%;
  }
}

.popoverTitle {
  font-weight: bold;
  margin-bottom: $margin-bottom !important;
  border-bottom: 2px solid $border-color;
  padding: $padding;
}

.notificationList {
  max-height: clamp(200px, 40vh, 300px) !important;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar-thumb-hover-color;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
    border-radius: $scrollbar-width;
  }

  &::-webkit-scrollbar:horizontal {
    height: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: $scrollbar-thumb-color;
    border-radius: $scrollbar-width;
  }

  &::-webkit-scrollbar-track:horizontal {
    background-color: $scrollbar-track-color;
    border-radius: $scrollbar-width;
  }
}

.notificationItem {
  display: flex;
  align-items: center;
  padding: $padding;
  max-width: clamp(350px, 60vw, 350px);
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
  margin-right: clamp(0.25rem, 0.5vw, 0.5rem);
  border-radius: clamp(0.5rem, 0.5vw, 0.7rem);

  &:last-child {
    border-bottom: none;
  }
}

.avatar {
  margin-right: $padding;
  width: $avatar-size;
  height: $avatar-size;
}

.notificationText {
  font-size: $font-size !important;
  color: $text-color;
}

.notificationTime {
  font-size: $font-size-small !important;
  color: $text-muted-color;
}
