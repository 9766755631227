@import 'assets/styles/variables';

.appLayout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  width: clamp(60px, 15vw, 250px);
}

.mainContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.header {
  width: 100%;
}

.dashboard {
  flex-grow: 1;
  padding: 20px;
  overflow: auto;
}
