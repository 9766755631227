@import 'assets/styles/variables';

.plansCard {
  padding: 1rem;
  border: solid 1px $border-color;

  .title {
    font-size: clamp(1rem, 1.5vw, 2.5rem);
  }
}

.progressContainer {
  display: flex;
  align-items: center;
}

.linearProgress {
  width: 100%;
  margin-right: 0.5rem;
  height: 8px;
  border-radius: 5px;

  .MuiLinearProgress-bar {
    border-radius: 5px; // Add border radius to the progress bar itself
  }
}

.salesBox {
  padding: 0.25rem 0.75rem;
  border-radius: 0.6rem;
  font-weight: bold;
  width: 2rem;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
}
