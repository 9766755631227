@import 'assets/styles/variables';

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.menuItem {
  display: flex;
  align-items: center;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  border-left: 2px solid rgba(255, 255, 255, 0.3);
  padding-left: 10px;
}

.link {
  display: flex;
  align-items: center;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  transition: 0.3s;
}

.link:hover {
  background-color: $primary-color;
  color: white;
}

.icon {
  margin-right: 10px;
}

.linkText {
  white-space: nowrap;
}
