@import 'assets/styles/variables';

.statItem {
  display: flex;
  align-items: center;
  padding: clamp(0.5rem, 1vw, 1rem);
  border-radius: 8px;
  background-color: white;
  border: 1px solid #e0e0e0;
  width: 100%;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(2.3rem, 4vw, 50px);
  height: clamp(2.3rem, 4vw, 50px);
  border-radius: 50%;
  margin-right: clamp(0.5rem, 1vw, 1rem);
}

.details {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  color: $stats-text-color;
}

.count {
  font-size: clamp(0.8rem, 3vw, 1.3rem);
  font-weight: bold;
  color: $stats-count-color;
}
