@import 'assets/styles/variables';

.container {
  width: clamp(300px, 40vw, 450px);
  padding: clamp(1rem, 2vw, 2rem);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.closeButton {
  position: absolute !important;
  top: clamp(0.5rem, 1vw, 1rem);
  right: clamp(0.5rem, 1vw, 1rem);
  color: #687182;
  cursor: pointer;
}

.emailIcon {
  width: clamp(50px, 8vw, 80px);
  height: clamp(50px, 8vw, 80px);
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
}

.title {
  font-weight: bold !important;
  margin-bottom: clamp(0.5rem, 1vw, 1rem) !important;
  color: #333333;
}

.subtitle {
  color: #687182;
  margin-bottom: clamp(1rem, 2vw, 2rem) !important;
}

.codeInputContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: clamp(1rem, 2vw, 2rem) !important;
}

.codeInput {
  width: clamp(40px, 8vw, 60px);
  height: clamp(50px, 10vw, 70px);
  text-align: center;
  font-size: clamp(1.5rem, 2vw, 2rem) !important;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  color: #333333;
}

.timer {
  color: #687182;
  margin-bottom: clamp(1rem, 2vw, 1.5rem) !important;
}

.verifyButton {
  width: 100%;
  padding: clamp(0.5rem, 1vw, 1rem);
  background-color: $primary-color !important;
  color: #ffffff;
  text-transform: none;
  border-radius: 8px;
  margin-top: 1rem;

  &:hover {
    background-color: #002244;
  }
}

.resendText {
  margin-top: clamp(1rem, 2vw, 2rem) !important;
  color: #687182;
}

.resendLink {
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
}
