@import 'assets/styles/variables';

$primary-text-color: #333;
$secondary-text-color: #666666;
$tertiary-text-color: #999999;
$background-color: #EDF2FC;
$button-color: #003466;

.viewMailContainer {
  padding: clamp(1rem, 2vw, 2rem);
  background-color: $background-color;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  margin: auto;
  position: relative;
}

.backButton {
  position: absolute;
  top: 10px;
  left: 10px;
  color: $primary-text-color;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
}

.subject {
  font-size: clamp(1rem, 2.5vw, 1.3rem) !important;
  font-weight: bold;
  color: $primary-text-color;
}

.timestamp {
  font-size: clamp(0.6rem, 1.5vw, 0.7rem) !important;
  color: $secondary-text-color;
}

.recipientInfo {
  display: flex;
  align-items: center;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
}

.avatar {
  width: clamp(2.5rem, 5vw, 3rem);
  height: clamp(2.5rem, 5vw, 3rem);
  margin-right: clamp(0.5rem, 1vw, 1rem);
}

.recipientName {
  font-size: clamp(0.8rem, 2vw, 1rem) !important;
  font-weight: bold;
  color: $primary-text-color;
}

.recipientEmail {
  font-size: clamp(0.7rem, 1.5vw, 0.8rem) !important;
  color: $secondary-text-color;
}

.messageContent {
  margin-bottom: clamp(2rem, 4vw, 3rem);
}

.greeting {
  font-size: clamp(0.7rem, 2vw, 1rem);
  font-weight: bold;
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
  color: $primary-text-color;
}

.messageBody {
  font-size: clamp(0.7rem, 1.5vw, 1rem) !important;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
  color: $primary-text-color;
  line-height: 1.6;
}

.actions {
  display: flex;
  gap: clamp(0.5rem, 1vw, 1rem);

  Button {
    background-color: $button-color;
    color: white;
    &:hover {
      background-color: darken($button-color, 10%);
    }
  }
}
