body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
a:-webkit-any-link {
  color: #666666;
}
a:-webkit-any-link:hover {
  text-decoration: underline;
}

.css-13cmg4v-MuiPaper-root-MuiCard-root {
  border: solid 1px #e0e0e0;
  padding: 1rem;
}

.css-yzf7bg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 1rem !important;
  padding: 0 !important;
}

.apexcharts-tooltip-marker {
  background-color: #fece00 !important;
}

.MuiTable-root {
  border-radius: 5px;
  padding: 1px;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  background-color: white !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #f5f7fc !important;
}

.css-19midj6 {
  padding: clamp(0.3rem, 1vw, 0.5rem) !important;
}

.css-bshv44-MuiButtonBase-root-MuiListItem-root {
  border-radius: 0.7rem !important;
  /* border-bottom: solid 2px #f5f7fc !important; */
  margin-bottom: 5px !important;
}

.css-10hburv-MuiTypography-root {
  font-size: clamp(0.4rem, 1vw, 0.9rem) !important;
}

.css-1e9lk82-MuiListItemAvatar-root {
  min-width: 1rem !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #003466 !important;
}

.css-w585gn-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #003466 !important;
}

.css-87g3fh.Mui-checked {
  color: #003466 !important;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
  color: white !important;
  background-color: #003466 !important;
  text-transform: none;
}
