@import 'assets/styles/variables';

.passwordContainer {
}

.tabTitle {
  font-weight: bold;
  color: #003366;
}

.formContainer {
  margin-top: 3rem;
}

.formFields {
  margin-bottom: 20px;
}

.textField {
  margin-bottom: 16px;
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .cancelButton {
      border-radius: 8px;
      color: #003466;
      border-color: #003466;
      text-transform: none;

      &:hover {
        background-color: #f4f7fc;
        border-color: #003466;
      }
    }

    .saveButton {
      border-radius: 8px;
      background-color: #003466;
      text-transform: none;

      &:hover {
        background-color: #002244;
      }
    }
  }
