@import 'assets/styles/variables';

.signupContainer {
  height: 100vh;
}

.leftSection,
.rightSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: clamp(1rem, 4vw, 3rem);
}
