.logoContainer {
  text-align: center;

  .logo {
    width: clamp(100px, 25vw, 200px); // Adjusted with clamp for responsiveness
    margin-bottom: 20px;
  }

  .title {
    font-size: clamp(1.5rem, 3vw, 2rem); // Using clamp for responsiveness
    color: #002b54;
    margin-bottom: 20px;
  }

  .description {
    color: #333;
    font-size: clamp(0.875rem, 1.5vw, 1rem); // Using clamp for responsiveness
    max-width: 400px;
    margin: 0 auto;
  }
}
