@import 'assets/styles/variables';

.formContainer {
  width: 100%;
  max-width: 400px;
  padding: clamp(1rem, 2vw, 2rem);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: clamp(1.5rem, 2vw, 2rem);
    margin-bottom: clamp(1rem, 2vw, 1.5rem);
    text-align: center;
    color: $dark-text-color;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .passwordOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: clamp(0.5rem, 1vw, 1rem);
  }

  .forgotPassword {
    font-size: clamp(0.875rem, 1vw, 1rem);
    text-decoration: underline;
    color: $dark-text-color;
  }

  .rememberMe {
    font-size: clamp(0.875rem, 1vw, 1rem);
    color: $dark-text-color;
  }

  .submitButton {
    background-color: $dark-text-color;
    color: #fff;
    margin-top: 20px;

    &:hover {
      background-color: darken($dark-text-color, 10%);
    }
  }

  .registerLink {
    text-align: center;
    margin: clamp(1rem, 2vw, 1.5rem) 0;
    font-size: clamp(0.875rem, 1.2vw, 1rem);
  }

  .divider {
    text-align: center;
    margin: clamp(1rem, 2vw, 1.5rem) 0;
    position: relative;

    &::before {
      content: '';
      height: 1px;
      background-color: #ddd;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
    }

    span {
      background-color: #fff;
      padding: 0 10px;
      position: relative;
      z-index: 1;
      color: $text-color;
      font-size: clamp(0.875rem, 1.2vw, 1rem);
    }
  }
}
