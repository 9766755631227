@import 'assets/styles/variables';

$selected-background-color: #003466;
$selected-text-color: #ffffff;
$secondary-text-color: #666666;
$tertiary-text-color: #999999;

.mailContainer {
  width: 100%;
  max-height: 100vh;
}

.mailItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #edf2fc;

  &.selected {
    background-color: $selected-background-color;
    color: $selected-text-color;

    .senderName,
    .subject,
    .message,
    .time {
      color: $selected-text-color;
    }
  }
}

.checkbox {
}

.mailContent {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.senderName {
  font-weight: bold !important;
  font-size: clamp(0.75rem, 2vw, 0.95rem) !important;
  margin-right: clamp(0.1rem, 1vw, 1rem) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subject {
  font-size: clamp(0.3rem, 2vw, 0.8rem) !important;
  font-weight: bold !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 600px) {
    display: none;
  }
}

.message {
  flex: 3;
  font-size: clamp(0.6rem, 1.5vw, 0.8rem) !important;
  color: $tertiary-text-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: clamp(0.5rem, 1vw, 1rem);
}

.time,
.deleteIcon {
  font-size: clamp(0.2rem, 1.5vw, 0.7rem) !important;
  font-weight: bold !important;
  color: $secondary-text-color;
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteIcon {
  color: $selected-text-color !important;
}
