@import 'assets/styles/variables';

.mainContainer {
  display: flex;
}

.sidebar {
  background: $background-color;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: clamp(0.625rem, 2vw, 1.25rem) clamp(0.3125rem, 1vw, 0.625rem);
}

.logo {
  min-width: 85%;
  font-size: clamp(1rem, 2vw, 1.5rem);
}

.logoImage {
  width: clamp(50%, 5vw, 80%);
}

.bars {
  margin-top: clamp(-0.3125rem, -1vw, -0.625rem) !important;
  color: $primary-color;
}

.routes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 2.2rem;
}

.link {
  display: flex;
  color: black !important;
  padding: clamp(0.4375rem, 1.5vw, 0.9375rem) clamp(0.625rem, 2vw, 1.25rem);
  transition: 0.3s;
  text-decoration: none;
  border-radius: clamp(0.25rem, 0.5vw, 0.5rem);
  position: relative;
  margin-right: clamp(0.3125rem, 1vw, 0.9375rem);
  margin-bottom: 2px;
  text-decoration: none !important;
  
  &.closed {
    margin-right: 5px;
  }
  
  .icon{
    padding-top: 3px;
  }
}

.link::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: clamp(0.25rem, 0.5vw, 0.5rem);
  background-color: transparent;
  border-radius: 0 clamp(0.25rem, 0.5vw, 0.5rem) clamp(0.25rem, 0.5vw, 0.5rem) 0;
  transition: background-color 0.3s;
}

.link:hover::before,
.active::before {
  background-color: $secondary-color;
}

.link:hover,
.active {
  background-color: $primary-color !important;
  color: white !important;
}

.icon {
  margin-right: clamp(0.625rem, 1.5vw, 1.25rem);
  z-index: 1;
}

.linkText {
  white-space: nowrap;
  z-index: 1;
}

.logoutSection {
  margin-top: auto;
  margin-bottom: clamp(5rem, 1vw, 10rem);
  background-color: $primary-color;
  border-radius: clamp(0.25rem, 0.5vw, 0.5rem);
  margin-right: clamp(0.3125rem, 1vw, 0.9375rem);
  width: 95%;

  .link {
    background-color: $primary-color;
    color: white !important;
    margin-right: clamp(0.3125rem, 1vw, 0.9375rem);

    &.closed {
      margin-right: 5px;
      width: 35%;
    }
    &::before {
      background-color: $secondary-color;
    }
  }
}
