@import 'assets/styles/variables';

.colsSettingsContainer {
  width: clamp(150px, 20vw, 200px);
}

.scrollableList {
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0bec5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #90a4ae;
  }

  &::-webkit-scrollbar-track {
    background-color: #eceff1;
    border-radius: 4px;
  }
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 2rem;
}

.checkbox {
  color: #003466;
}

.listItemText {
  span {
    font-size: clamp(0.9rem, 2vw, 1rem);
    color: #687182;
    font-weight: 500;
  }
}
