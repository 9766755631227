@import 'assets/styles/_variables.scss';

.tableContainer {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0aec0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f4f7fc;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #a0aec0;
  }

  &::-webkit-scrollbar-track:horizontal {
    background-color: #f4f7fc;
  }
}

.buttonsBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .buttons {
    display: flex;
    gap: 1rem;
    @media (max-width: 680px) {
      flex-direction: column;
    }
  }
}

.tableHeadCell {
  background-color: #f4f7fc !important;
  font-weight: bold !important;
  color: #2c3e50 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableBodyCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #687182 !important;
}

.tableFooterRow {
  display: flex !important;
  justify-content: flex-end !important;
  padding-right: 1rem;
}

.customTabs {
  display: flex;
  margin-bottom: 1rem;
  border-bottom: solid 2px #ecedef;

  .MuiTab-root {
    text-transform: none;
    font-weight: bold;
    color: #666666;
    border-radius: 8px 8px 0 0;
    padding: 12px 24px;
    min-width: 0;
    min-height: 0;
    margin-right: 16px;

    &.Mui-selected {
      color: #003466;
      background-color: #f5f7fc;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
    }
  }

  .tab {
    background-color: transparent;
    &:hover {
      background-color: #f5f7fc;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }

  .activeTab {
    background-color: #f5f7fc;
    color: $primary-color;
    font-weight: bold;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}
