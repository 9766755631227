@import 'assets/styles/variables';

.performanceCard {
  padding: clamp(0.3rem, 1.5vw, 1rem);
  border: solid 1px $border-color;
  border-radius: 0.6rem;
  box-shadow: none;

  .title {
    font-size: clamp(1rem, 1.5vw, 2.5rem);
  }

  .subTitle {
    font-size: clamp(0.75rem, 1.5vw, 1.1rem);
  }

  .divider {
    margin-bottom: 1rem;
  }
}

.cardHeader {
  .MuiCardHeader-subheader {
    font-size: 0.875rem;
    color: $secondary-color;
  }
}

.employeeBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $background-color;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.employeeInfo {
  display: flex;
  align-items: center;
}

.avatar {
  width: 48px;
  height: 48px;
  margin-right: 1rem;
}

.employeeName {
  font-weight: 600 !important;
  font-size: clamp(0.675rem, 1vw + 0.5rem, 0.9rem) !important;
}

.employeePlan {
  font-weight: 600 !important;
  font-size: clamp(0.675rem, 1vw + 0.5rem, 0.9rem) !important;
  text-align: right;
}
