@import 'assets/styles/variables';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.modalContent {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: 85vh;
  overflow-y: auto;

  @media (min-width: 680px) {
    max-width: 50%;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0aec0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #90a4ae;
  }

  &::-webkit-scrollbar-track {
    background-color: #eceff1;
    border-radius: 4px;
  }
}

.closeButton {
  position: absolute;
  top: clamp(0.5rem, 1vw, 1rem);
  right: clamp(0.5rem, 1vw, 1rem);
  color: $icon-color;
  cursor: pointer;
}
