@import 'assets/styles/variables';

.formContainer {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: $dark-text-color;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .terms {
    font-size: 0.875rem;
    color: $text-color;
    margin: 10px 0;
    text-align: center;
  }

  .submitButton {
    background-color: $dark-text-color;
    color: #fff;
    margin-top: 20px;

    &:hover {
      background-color: darken($dark-text-color, 10%);
    }
  }

  .loginLink {
    text-align: center;
    margin: 20px 0;
  }

  .divider {
    text-align: center;
    margin: 20px 0;
    position: relative;

    &::before {
      content: '';
      height: 1px;
      background-color: #ddd;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
    }

    span {
      background-color: #fff;
      padding: 0 10px;
      position: relative;
      z-index: 1;
      color: $text-color;
    }
  }
}
