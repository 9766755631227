.refundRequestContainer {
  padding: 2rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  .title {
    font-weight: 600;
    color: #1d4e89;
    margin-bottom: 1.5rem;
  }

  .textField {
    margin-bottom: 1rem;

    input {
      background-color: #f9f9f9;
    }
  }

  .actionButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    .cancelButton {
      margin-right: 1rem;
      color: #1d4e89;
      border-color: #1d4e89;

      &:hover {
        background-color: #f5f5f5;
      }
    }

    .submitButton {
      background-color: #1d4e89;
      color: white;

      &:hover {
        background-color: #154067;
      }
    }
  }
}
