@import 'assets/styles/variables';

.formContainer {
  padding: clamp(1rem, 2vw, 2rem);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;

  h2 {
    font-size: clamp(1.5rem, 2vw, 2rem);
    margin-bottom: clamp(1rem, 2vw, 1.5rem);
    color: $dark-text-color;
  }

  .lockIcon {
    font-size: clamp(3rem, 5vw, 4rem);
    color: $dark-text-color;
    margin-bottom: clamp(1rem, 2vw, 1.5rem);
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $text-color;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .submitButton {
    background-color: $dark-text-color;
    color: #fff;
    margin-top: 20px;

    &:hover {
      background-color: darken($dark-text-color, 10%);
    }
  }

  .backToLogin {
    margin-top: clamp(1rem, 2vw, 1.5rem);
    font-size: clamp(0.875rem, 1.2vw, 1rem);
    text-decoration: underline;
    color: $dark-text-color;
    display: block;
  }
}
