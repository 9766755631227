@import 'assets/styles/variables';

.searchContainer {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: clamp(0.025rem, 0.5vw, 0.05rem);
  width: 50%;
}

.inputRoot {
  color: inherit;
  padding-left: clamp(0.5rem, 1vw, 1rem);
}

.inputInput {
  width: 100%;
  font-size: clamp(0.875rem, 1vw, 1rem);
}

.searchIcon {
  padding: 0;
  color: $icon-color;
}
