@import 'assets/styles/variables';

.earlyPaymentContainer {
  padding: clamp(1rem, 2vw, 2rem);
  background-color: $background-color;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: solid 1px #d9dbe9;
}

.title {
  font-size: clamp(1rem, 2.5vw, 1.5rem) !important;
  font-weight: bold;
  color: $primary-color !important;
  margin-bottom: clamp(0.5rem, 2vw, 1rem);
}

.formContainer {
  margin-top: 1rem;
}

.textField {
  margin-bottom: clamp(0.2rem, 2vw, 0.5rem);
}

.tableContainer {
}

.tableTitle {
  font-size: clamp(1rem, 2vw, 0.9rem) !important;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: clamp(0.3rem, 2vw, 0.5rem);
  margin-top: clamp(0.2rem, 2vw, 0.5rem);
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: clamp(0.5rem, 1vw, 1rem);
  margin-top: clamp(0.5rem, 4vw, 1rem);

  .rejectButton {
    border-color: $primary-color;
    color: $primary-color;
  }

  .acceptButton {
    background-color: $primary-color;
    color: white;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}
